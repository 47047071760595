import Notification from '../../notification/notification';
import { Button, Card, Col, Row } from 'react-bootstrap';
import threeDot from '../../../_admin/assets/media/svg_uTrade/three-dot-round.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { orderDeliveryJSON } from '../../../api/apiJSON/order';
import Loader from '../../../Global/loader';
import {
  CartonWithDozens,
  CartonWithPieces,
  Dozen,
  FinanceManager,
  OrderCancelled,
  OrderDelivered,
  OrderCard,
  OrderCash,
  OrderMPesa,
  OrderProcessed,
  OrderSubmitted,
  OrderTigoPesa,
  Piece,
  RouteOrderNotDispatched,
  RouteVehicleOrderDelivered,
  RouteOrderMovedToPendingOrder,
  RouteOrderCustomerNotAvailable,
  RouteOrderDispatched,
  LoadingBayManager,
  FulfillmentManager,
  OrdersDelivery,
  Edit,
  AllModules,
} from '../../../utils/constants';
import Method from '../../../utils/methods';
import { CustomSelectTable } from '../../custom/Select/CustomSelectTable';
import { useAuth } from '../auth';
import { IMAGES } from '../../../utils/dummyJSON';
import clsx from 'clsx';
import InstantOrderBatchInfo from '../../modals/instant-order-batch-info';
import PartialPaymentTransactionDetails from '../../modals/partial-payment-transaction-details';
import OrderDeliveryTimeline from '../orders-delivery/order-timeline';
import APICallService from '../../../api/apiCallService';
import { interTransferRequest } from '../../../api/apiEndPoints';
const InterTransferDetail = () => {
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const { state }: any = useLocation();
  const [fetchLoader, setFetchLoader] = useState(true);
  const [download, setDownload] = useState(false);
  const [batchInfo, setBatchInfo] = useState(false);
  const [transaction, setTransaction] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [details, setDetails] = useState<any>();
  console.log('state', state);
  const options = [
    {
      label: (
        <button className="btn btn-link fs-12 fw-400 ms-3 p-4">
          Edit product & quantities
        </button>
      ),
      value: 1,
    },
    {
      label: (
        <button className="btn btn-link fs-12 fw-400 text-danger ms-3 p-4">
          Cancel Order
        </button>
      ),
      value: 2,
    },
  ];
  useEffect(() => {
    (async () => {
      setFetchLoader(true);
      await fetchDetails();
      setFetchLoader(false);
    })();
  }, []);
  const fetchDetails = async () => {
    let apiService = new APICallService(
      interTransferRequest.tranferDetail,
      state.id
    );
    let response = await apiService.callAPI();
    setDetails(response.record);
  };
  //   const handleStatus = async (status: any) => {
  //     setLoading(true);
  //     let apiService = new APICallService(
  //       ordersDelivery.status,
  //       orderDeliveryJSON.updateOrderStatus({ status: status }),
  //       { id: state?.id },
  //       '',
  //       '',
  //       '',
  //       OrdersDelivery
  //     );
  //     let response = await apiService.callAPI();
  //     if (response) {
  //       let temp = { ...details };
  //       temp.status = status;
  //       setDetails(temp);
  //     }
  //     setLoading(false);
  //   };
  const openMenuOnClick = async () => {
    setShow(true);
  };
  const onMenuClose = async () => {
    setShow(false);
  };
  const onMenuOpen = async () => {
    setShow(true);
  };
  //   const handleOption = async (event: any, data: any) => {
  //     if (event.value === 1) {
  //       // let temp = { ...data };
  //       // let variantTemp: any = [];
  //       // temp.variants.map((val: any) => {
  //       //   variantTemp.push(val.variant._id);
  //       // });
  //       navigate('/orders/edit-order-details', {
  //         state: data._id,
  //       });
  //     } else if (event.value === 2) {
  //       let params: any = { status: OrderCancelled };
  //       setFetchLoader(true);
  //       let apiService = new APICallService(
  //         ordersDelivery.status,
  //         params,
  //         {
  //           id: state.id,
  //         },
  //         '',
  //         '',
  //         '',
  //         OrdersDelivery
  //       );
  //       let response = await apiService.callAPI();
  //       if (response.cancelled) {
  //         details.status = OrderCancelled;
  //         details.statusesLogs.push({
  //           status: OrderCancelled,
  //           statusUpdatedAt: new Date(),
  //           _id: '1',
  //         });
  //         await setDetails(details);
  //       }
  //       setFetchLoader(false);
  //     }
  //   };
  //   const handleDownload = async () => {
  //     setDownload(true);
  //     let apiService = new APICallService(
  //       ordersDelivery.invoiceDownload,
  //       state.id,
  //       undefined,
  //       'blob',
  //       '',
  //       '',
  //       OrdersDelivery
  //     );
  //     let response = await apiService.callAPI();
  //     if (response) {
  //       const pdfBlob = new Blob([response], { type: 'application/pdf' });
  //       const downloadLink = document.createElement('a');
  //       downloadLink.href = URL.createObjectURL(pdfBlob);
  //       downloadLink.download = 'order_' + details.refKey + '.pdf';
  //       downloadLink.click();
  //     }
  //     setDownload(false);
  //   };
  const handleShow = (index: number) => {
    let temp = { ...details };
    temp.routesVehicles[index].isOpen = !temp.routesVehicles[index].isOpen;
    setDetails(temp);
  };
  console.log('details', details);
  return (
    <>
      <div className="p-9">
        <Row className="g-8">
          {!fetchLoader ? (
            <>
              {' '}
              {details && Object.keys(details).length ? (
                <>
                  <Col md={12}>
                    <Row className="align-items-center g-3">
                      <Col xs>
                        <div className="d-flex align-items-center">
                          <h1 className="fs-22 fw-bolder mb-0 me-4">
                            Transfer details
                          </h1>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12}>
                    <Card className="border">
                      <Card.Header className="bg-light align-items-center">
                        <div className="d-flex align-items-center justify-content-between w-100">
                          <Card.Title className="fs-22 fw-bolder">
                            Basic details
                          </Card.Title>
                          {details.status === OrderSubmitted ? (
                            <span className="badge badge-light-warning fs-14 fw-600 text-dark p-3 border-r4px">
                              Unprocessed
                            </span>
                          ) : (
                            <></>
                          )}
                        </div>
                      </Card.Header>
                      <Card.Body className="pb-3">
                        {/* <Row className="mb-5">
                          <Col
                            md={5}
                            xs={4}
                          >
                            <label className=" fs-16 fw-500 text-dark">
                              Customer name:
                            </label>
                          </Col>
                          <Col
                            md={7}
                            xs={8}
                          >
                            <span className="fw-bold fs-16 fw-600 text-dark">
                              {details?.customer?.name}
                            </span>
                          </Col>
                        </Row> */}
                        <Row className="mb-5">
                          <Col
                            md={5}
                            xs={4}
                          >
                            <label className=" fs-16 fw-500 text-dark">
                              Order ID:
                            </label>
                          </Col>
                          <Col
                            md={7}
                            xs={8}
                          >
                            <span className="fw-bold fs-16 fw-600 text-dark">
                              {details?.refKey}
                            </span>
                          </Col>
                        </Row>
                        <Row className="mb-5">
                          <Col
                            md={5}
                            xs={4}
                          >
                            <label className=" fs-16 fw-500 text-dark">
                              Order placed on:
                            </label>
                          </Col>
                          <Col
                            md={7}
                            xs={8}
                          >
                            <span className="fw-bold fs-16 fw-600 text-dark">
                              {Method.convertDateToDDMMYYYYHHMM(
                                details?._createdAt,
                                '-'
                              )}
                            </span>
                          </Col>
                        </Row>
                        <Row className="mb-5">
                          <Col
                            md={5}
                            xs={4}
                          >
                            <label className=" fs-16 fw-500 text-dark">
                              Payment method:
                            </label>
                          </Col>
                          <Col
                            md={7}
                            xs={8}
                          >
                            {details.payment.transactions.length ? (
                              <>
                                <span className="fw-bold fs-16 fw-600 text-dark">
                                  {details.payment.transactions[
                                    details.payment.transactions.length - 1
                                  ].paymentMethod === OrderCash
                                    ? 'Cash'
                                    : ''}
                                  {details.payment.transactions[
                                    details.payment.transactions.length - 1
                                  ].paymentMethod === OrderTigoPesa
                                    ? 'Tigo pesa'
                                    : ''}
                                  {details.payment.transactions[
                                    details.payment.transactions.length - 1
                                  ].paymentMethod === OrderMPesa
                                    ? 'MPesa'
                                    : ''}
                                  {details.payment.transactions[
                                    details.payment.transactions.length - 1
                                  ].paymentMethod === OrderCard
                                    ? 'Card'
                                    : ''}
                                </span>{' '}
                              </>
                            ) : (
                              <>
                                <span className="fw-bold fs-16 fw-600 text-dark">
                                  {details.payment.paymentMode === OrderCash
                                    ? 'Cash'
                                    : ''}
                                  {details.payment.paymentMode === OrderTigoPesa
                                    ? 'Tigo pesa'
                                    : ''}
                                  {details.payment.paymentMode === OrderMPesa
                                    ? 'MPesa'
                                    : ''}
                                  {details.payment.paymentMode === OrderCard
                                    ? 'Card'
                                    : ''}
                                </span>
                              </>
                            )}
                            <span className="fw-bold fs-16 fw-600 text-dark text-decoration">
                              {details.payment.paymentMode === OrderCash
                                ? 'Cash'
                                : ''}
                              {details.payment.paymentMode === OrderTigoPesa
                                ? 'Tigo pesa'
                                : ''}
                              {details.payment.paymentMode === OrderMPesa
                                ? 'MPesa'
                                : ''}
                              {details.payment.paymentMode === OrderCard
                                ? 'Card'
                                : ''}
                            </span>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col>
                  {/* <Col md={6}>
                    <Card className="border">
                      <Card.Header className="bg-light align-items-center">
                        <Card.Title className="fs-22 fw-bolder">
                          Delivery details
                        </Card.Title>
                      </Card.Header>
                      <Card.Body className="pb-3">
                        <Row className="mb-5">
                          <Col
                            md={5}
                            xs={4}
                          >
                            <label className=" fs-16 fw-500 text-dark">
                              Shop name:
                            </label>
                          </Col>
                          <Col
                            md={7}
                            xs={8}
                          >
                            <span className="fw-bold fs-16 fw-600 text-dark">
                              {details.address.name}
                            </span>
                          </Col>
                        </Row>
                        <Row className="mb-5">
                          <Col
                            md={5}
                            xs={4}
                          >
                            <label className=" fs-16 fw-500 text-dark">
                              Phone number:
                            </label>
                          </Col>
                          <Col
                            md={7}
                            xs={8}
                          >
                            <span className="fw-bold fs-16 fw-600 text-dark">
                              {details.address.phoneCountry}{' '}
                              {details.address.phone}{' '}
                            </span>
                          </Col>
                        </Row>
                        <Row className="mb-5">
                          <Col
                            md={5}
                            xs={4}
                          >
                            <label className=" fs-16 fw-500 text-dark">
                              Delivery address:
                            </label>
                          </Col>
                          <Col
                            md={7}
                            xs={8}
                          >
                            <span className="fw-bold fs-16 fw-600 text-dark">
                              {details.address.addressLine1}{' '}
                              {details.address.landmark
                                ? details.address.landmark
                                : ''}
                            </span>
                          </Col>
                        </Row>
                        <Row className="mb-5">
                          <Col
                            md={5}
                            xs={4}
                          >
                            <label className=" fs-16 fw-500 text-dark">
                              District & city:
                            </label>
                          </Col>
                          <Col
                            md={7}
                            xs={8}
                          >
                            <span className="fw-bold fs-16 fw-600 text-dark">
                              {details.address.districtName},{' '}
                              {details.address.city}
                            </span>
                          </Col>
                        </Row>
                      </Card.Body>
                    </Card>
                  </Col> */}
                </>
              ) : (
                <></>
              )}
              <Col xs={12}>
                <Card className="p-0 bg-light">
                  <Card.Body className="p-0">
                    <div className="table-responsive">
                      <table className="table table-rounded table-row-bordered align-middle gs-9 gy-4 mb-0">
                        <thead>
                          <tr className="fs-16 fw-600 h-60px align-middle">
                            <th className="w-md-475px min-w-275px">
                              Product name
                            </th>
                            <th className="min-w-md-150px">Unit price</th>
                            <th className="w-md-200px">Qty. & Type</th>
                            <th className="w-md-125px">Tax(%)</th>
                            <th className="min-w-md-100px ">Tax amount</th>
                            <th className="min-w-md-100px text-end">
                              Total amount
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {details?.variants?.map((val: any) => (
                            <tr key={val.variant._id}>
                              <td>
                                <div className="d-flex align-items-center">
                                  <div className="symbol symbol-50px border">
                                    <span
                                      className="symbol-label bgi-contain"
                                      style={{
                                        backgroundImage: `url(${
                                          val.variant.media[0]?.url || ''
                                        })`,
                                      }}
                                    ></span>
                                  </div>
                                  <div className="d-flex flex-column ms-5">
                                    <span className="text-dark fw-600 fs-15 d-block">
                                      {val.variant.title}
                                    </span>
                                    <span className="text-dark fw-500 fs-15 d-block">
                                      {val.variant.product.business.name}
                                    </span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <span className="fs-15 fw-500">
                                  TSh {Method.getGeneralizedAmount(val.amount)}
                                </span>
                              </td>
                              <td>
                                <span className="fs-15 fw-500">
                                  {val.stockCount}{' '}
                                  {val.quantityType === CartonWithDozens ||
                                  val.quantityType === CartonWithPieces
                                    ? 'Cartons'
                                    : ''}
                                  {val.quantityType === Dozen ? 'Dozen' : ''}
                                  {val.quantityType === Piece ? 'Piece' : ''}
                                </span>
                              </td>
                              <td>
                                <span className="fs-15 fw-500">
                                  {val.productTax ? val.productTax + '%' : '0%'}
                                </span>
                              </td>
                              <td className="">
                                <span className="fs-15 fw-500">
                                  TSh{' '}
                                  {val.productTax
                                    ? Method.getGeneralizedAmount(
                                        val.totalAmount - val.beforeTaxAmount
                                      )
                                    : '0'}
                                </span>
                              </td>
                              <td className="text-end">
                                <span className="fs-15 fw-500">
                                  TSh{' '}
                                  {Method.getGeneralizedAmount(
                                    val?.totalAmount
                                  )}
                                </span>
                              </td>
                            </tr>
                          ))}
                          <tr>
                            <td
                              colSpan={5}
                              className="fs-16 fw-600 text-end"
                            >
                              Subtotal:
                            </td>
                            <td
                              colSpan={1}
                              className="fs-16 fw-600 text-left"
                            >
                              TSh{' '}
                              {Method.getGeneralizedAmount(
                                details?.variants?.reduce((p: any, c: any) => {
                                  let actualCharge =
                                    c?.stockCount * c?.originalAmount;
                                  return p + actualCharge;
                                }, 0)
                              )}
                            </td>
                          </tr>
                          {details?.variants?.reduce((p: any, c: any) => {
                            let actualCharge = c.stockCount * c.originalAmount;
                            let discountedCharge = c.stockCount * c.amount;
                            return p + actualCharge - discountedCharge;
                          }, 0) > 0 ? (
                            <tr>
                              <td
                                colSpan={5}
                                className="fs-16 fw-600 text-end"
                              >
                                Product Discount:
                              </td>
                              <td
                                colSpan={1}
                                className="fs-16 fw-600 text-left"
                              >
                                - TSh{' '}
                                {Method.getGeneralizedAmount(
                                  details?.variants?.reduce(
                                    (p: any, c: any) => {
                                      let actualCharge =
                                        c.stockCount * c.originalAmount;
                                      let discountedCharge =
                                        c.stockCount * c.amount;
                                      return (
                                        p + actualCharge - discountedCharge
                                      );
                                    },
                                    0
                                  )
                                )}
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                          {details.payment.taxes?.length ? (
                            <tr>
                              <td
                                colSpan={5}
                                className="fs-16 fw-600 text-end"
                              >
                                {' '}
                                Tax
                                {details.payment.taxes[0].value === 2
                                  ? '(2%)'
                                  : ''}
                                :
                              </td>
                              <td
                                colSpan={1}
                                className="fs-16 fw-600 text-left"
                              >
                                TSh{' '}
                                {Method.getGeneralizedAmount(
                                  details.payment.taxes.reduce(
                                    (p: any, c: any) => p + c.appliedCharge,
                                    0
                                  )
                                )}
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}
                          <tr>
                            <td
                              colSpan={5}
                              className="fs-22 fw-bold text-end"
                            >
                              Grand Total:
                            </td>
                            <td
                              colSpan={1}
                              className="text-dark fs-22 fw-bold text-left"
                            >
                              TSh{' '}
                              {Method.getGeneralizedAmount(
                                details?.payment?.totalCharge
                              )}
                            </td>
                          </tr>
                          {details.variants.reduce(
                            (p: any, c: any) => p + c.commissionAmount,
                            0
                          ) ? (
                            <tr>
                              <td
                                colSpan={5}
                                className="fs-16 fw-600 text-end"
                              >
                                U-Trade Commission:
                              </td>
                              <td
                                colSpan={1}
                                className="fs-16 fw-600 text-left"
                              >
                                TSh{' '}
                                {Method.getGeneralizedAmount(
                                  details?.variants?.reduce(
                                    (p: any, c: any) => p + c.commissionAmount,
                                    0
                                  )
                                )}
                              </td>
                            </tr>
                          ) : (
                            <></>
                          )}{' '}
                        </tbody>
                      </table>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </>
          ) : (
            <>
              <Col lg={12}>
                <div className="d-flex justify-content-center">
                  <Loader loading={fetchLoader} />
                </div>
              </Col>
            </>
          )}
        </Row>
      </div>
    </>
  );
};
export default InterTransferDetail;
