import { Suspense, useEffect, useRef, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { I18nProvider } from '../_admin/i18n/i18nProvider';
import { LayoutProvider, LayoutSplashScreen } from '../_admin/layout/core';
import { MasterInit } from '../_admin/layout/MasterInit';
import { AuthInit } from './modules/auth';
function ScrollToTop() {
  const { pathname } = useLocation();
  const prevPathname: any = useRef();
}
const App = () => {
  const { pathname } = useLocation();
  const prevPathname: any = useRef();
  // Initialize scroll position from localStorage or set to 0
  const initialScrollPosition: any = localStorage.getItem('scrollPosition')
    ? localStorage.getItem('scrollPosition')
    : 0 || 0;
  // Set the scroll position in the component state
  const [scrollY, setScrollY] = useState<any>(parseInt(initialScrollPosition));
  // Update the scroll position in localStorage whenever the user scrolls
  useEffect(() => {
    const handleScroll = () => {
      let currentScrollY: any = window.scrollY;
      setScrollY(currentScrollY);
      localStorage.setItem('scrollPosition', currentScrollY);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  // Scroll to the saved position when the component mounts
  // useEffect(() => {
  //   window.scrollTo(0, scrollY);
  // }, [scrollY]);
  // useEffect(() => {
  //   if (pathname !== prevPathname.current) {
  //     window.scrollTo(0, 0);
  //     prevPathname.current = pathname;
  //   }
  // }, [pathname]);
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <I18nProvider>
        <LayoutProvider>
          <AuthInit>
            <Outlet />
            {/* <ScrollToTop /> */}
            <MasterInit />
          </AuthInit>
        </LayoutProvider>
      </I18nProvider>
    </Suspense>
  );
};
export { App };
